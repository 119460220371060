<template>
    <div class="main">
        <Breadcrumb :breadcrumb="breadcrumb"/>
        <form-purchase />
        <MetaTags/>
    </div>
</template>
<script>
import FormPurchase from './components/form-purchase.vue'
export default {
  components: { FormPurchase },
  data() {
    return {
      breadcrumb: [
        {
          link: `${this.$route.fullPath}`,
          title: "予約内容確認"
        }
      ]
    }
  },
}
</script>
<style lang="">
    
</style>